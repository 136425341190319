import { Component } from 'react'
import moment from 'moment'
import { ClockRange, JulianDate } from 'cesium'
import { createFlightEntity } from './FlightEntity'
import { listContains } from '../utilities/Helpers'

export class MapContents extends Component {

	/**
	 * The main constructor.
	 * @param props {Object} properties
	 * @param props.flightData {FlightData} flight data
	 * @param props.options {Object} any replay-specific options
	 * @param props.timelineStart {Date|String} the start datetime for the replay
	 * @param props.selectedFlights {String[]} a list of selected flight identifiers
	 * @param props.settings {NimbusSettings} all the current Nimbus settings
	 * @param props.viewer {Viewer} the viewer
	 */
	constructor (props) {
		super(props);
		this.viewer = props.viewer;
		this.flightData = props.flightData;
		this.options = props.options;
		this.selectedFlights = props.selectedFlights;
		this.startTime = JulianDate.fromIso8601(moment().toISOString());
		this.stopTime = JulianDate.fromIso8601(moment().add(10, "minutes").toISOString());
		this.state = {
			flightEntities: this.flightEntitiesFromFlightData(props.flightData, props.settings),
			timelineStart: props.timelineStart,
			settings: props.settings
		};
	}

	/**
	 * Runs when the component will receive new properties.
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps(nextProps, nextContext) {
		if (this.flightData !== nextProps.flightData || this.state.settings !== nextProps.settings) {
			let newFlightEntities = this.flightEntitiesFromFlightData(nextProps.flightData, nextProps.settings);
			this.setState({
				flightEntities: newFlightEntities,
				timelineStart: nextProps.timelineStart,
				settings: nextProps.settings
			}, () => {
				this.loadFlightEntities();
			})
		}
	}

	/**
	 * Run when the component is ready to mount.
	 */
	componentWillMount() {
		this.loadFlightEntities();
	}

	/**
	 * Clean up after yourself when the component unloads. Pay particular
	 * attention to memory leaks in flightEntities.
	 */
	componentWillUnmount() {
		if(this.state.flightEntities) {
			delete this.state.flightEntities;
		}
	}

	/**
	 * Converts raw flight data into flight entities.
	 * @param flightData {FlightData} the flight data object
	 * @param settings {NimbusSettings} current Nimbus settings
	 * @return {[Entity]}
	 */
	flightEntitiesFromFlightData(flightData, settings) {
		let models = [];
		if (flightData) {
			for (let identifier in flightData) {
				if (flightData.hasOwnProperty(identifier)) {
					let flight = flightData[identifier];
					let flightEntity = createFlightEntity(identifier, {
						flightLabelFontSize: settings.flightLabelFontSize.value,
						icaoType: flight.icaoType,
						minimumPixelSize: settings.flightMinimumPixelSize.value,
						pathLeadTime: settings.flightPathLeadTime.value,
						pathTrailTime: settings.flightPathTrailTime.value,
						pathWidth: settings.flightPathWidth.value,
						pathColor: settings.flightPathColor.value,
						silhouetteAlpha: settings.flightModelSelectedSilhouetteAlpha.value,
						silhouetteSize: settings.flightModelSelectedSilhouetteSize.value
					});
					flightEntity.addPointsFromList(flight.positionReports);
					if (flight.isGhost) {
						flightEntity.isGhost(flight.isGhost);
						flightEntity.setExtrapolateForwards(30);
					}
					// Added auto support for ghosts that contain "ghost" in the callsign
					if (identifier.includes("ghost")) {
						flightEntity.isGhost(true);
					}

					// If the flight has wake disks
					if (flight.hasOwnProperty("wakeReports") && flight.wakeReports != null) {
						flightEntity.isWakeEmitter(true, flight.wakeReports);
					}

					models.push(flightEntity);
				}
			}
		}
		return models;
	}

	/**
	 * Load all flight objects into the model. This function requires all
	 * flight entities to already be created and stored in the flightEntities
	 * object.
	 */
	loadFlightEntities() {
		this.removeAllEntities();
		if (this.state.flightEntities.length > 0) {
			this.state.flightEntities.forEach(entity => {
				entity.updateFlight();
				if (listContains(this.viewer.selectedFlights, entity.identifier)) {
					entity.isSelected(true);
				}
				this.viewer.entities.add(entity);
				if (entity.hasOwnProperty("wakeDisks") && entity["wakeDisks"].length > 0) {
					entity["wakeDisks"].forEach((wd)=>{
						this.viewer.entities.add(wd);
					})
				}
			})
			this.updateViewerStartEndTime();
			this.viewer.zoomTo(this.viewer.entities);
		}
	}

	/**
	 * Modifies the timeline of the viewer to accommodate the availability of an
	 * entity.
	 */
	updateViewerStartEndTime () {
		if (this.state.flightEntities.length > 0) {
			this.startTime = null;
			this.stopTime = null;
			this.state.flightEntities.forEach(entity => {
				if (entity.availabilty.start < this.startTime || this.stopTime === null) {
					this.startTime = entity.availabilty.start;
				}
				if (entity.availabilty.stop > this.stopTime || this.stopTime === null) {
					this.stopTime = entity.availabilty.stop;
				}
			})
			this.viewer.clock.startTime = this.startTime.clone();
			this.viewer.clock.stopTime = this.stopTime.clone();
			this.viewer.clock.clockRange = ClockRange.LOOP_STOP; //Loop at the end
			this.viewer.clock.multiplier = 1;
			if (!this.state.timelineStart) {
				this.viewer.clock.currentTime = JulianDate.addSeconds(this.startTime, 5, this.startTime.clone());
			} else {
				this.viewer.clock.currentTime = JulianDate.fromDate(this.state.timelineStart);
			}
			this.viewer.timeline.zoomTo(this.startTime, this.stopTime);
		}
	}

	/**
	 * Remove all existing entities from the viewer.
	 */
	removeAllEntities() {
		this.viewer.entities.removeAll();
	}

	/**
	 * The class has a null return for the render loop since it interacts directly
	 * with the Cesium viewer rather than the DOM.
	 * @return {null}
	 */
	render() {
		return null;
	}

}

export default MapContents;

/**
 * Parse a datetime string into a Date object.
 * @param datetimeString {string} the datetime string
 * @return {Date} the parsed date
 */
export function parseDatetime(datetimeString) {
	// Add 'Z' to timestamp in case it is missing to force UTC
	if (datetimeString[datetimeString.length-1] !== "Z") {
		datetimeString += "Z"
	}
	return new Date(datetimeString);
}

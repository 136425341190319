import {DEFAULT_SETTINGS} from "../settings";
import {isEmpty} from "../utilities/BooleanLogic";

const SETTINGS_KEY = "nimbus-settings";

const SettingsService = {

	/**
	 * Gets all settings from localstorage. This uses the spread notation to
	 * merge the default settings object with the local object (with local set
	 * last so it prioritizes local values over default values. This ensures
	 * that we always get at least the default value even if it is new and not
	 * stored on the local system.
	 * @returns {NimbusSettings} the settings
	 */
	getAll() {
		let settingsJson = localStorage.getItem(SETTINGS_KEY);
		if (settingsJson) {
			try {
				settingsJson = {...DEFAULT_SETTINGS, ...JSON.parse(settingsJson)};
				return settingsJson;
			} catch (e) {
				throw Error("Unable to parse local settings from JSON: " + e.toLocaleString());
			} finally {
				localStorage.setItem(SETTINGS_KEY, JSON.stringify(settingsJson));
			}
		} else {
			return this.reset(this.getAll);
		}
	},

	/**
	 * Get a setting for Nimbus
	 * @param settingsKey {string}
	 * @returns {Any|undefined} the
	 */
	get(settingsKey) {
		let settings = this.getAll();
		return settings[settingsKey];
	},

	/**
	 * Returns all of the setting keys
	 * @returns {string[]} an array of keys
	 */
	keys() {
		return Object.keys(this.getAll());
	},

	/**
	 * Resets (or creates if not existing) the default Nimbus settings object
	 * @param callback {function=} optional callback to fire after the reset
	 */
	reset(callback) {
		localStorage.setItem("nimbus-settings", JSON.stringify(DEFAULT_SETTINGS));
		if (callback) {
			return callback();
		}
	},

	/**
	 * Set the value of a setting and persist this in local storage. The key
	 * must exist already in the DEFAULT_SETTINGS object otherwise an error
	 * will be thrown.
	 * @param settingKey {String} the key
	 * @param settingValue {any} the value
	 * @param callback {function=} any callback
	 */
	set(settingKey, settingValue, callback) {
		if (!this._settingsHasDefault(settingKey)) {
			throw Error("Unrecognized setting key: " + settingKey);
		} else if (isEmpty(settingValue)) {
			throw Error("Blank values are not valid for settings: " + settingKey);
		// } else if (typeof settingValue !== typeof DEFAULT_SETTINGS[settingKey]) {
		// 	throw Error("Incorrect type for " + settingKey + ". Expected " + typeof DEFAULT_SETTINGS[settingKey]);
		} else {
			let settings = this.getAll();
			settings[settingKey].value = settingValue;
			localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
			if (callback) callback(settings);
		}
	},

	/**
	 * Update all the Nimbus settings. Iterates through each so that every value
	 * is checked according to the set rules above.
	 * @param settings {NimbusSettings} the settings
	 * @param successCallback {function=} function to call if the update is good
	 * @param failureCallback {function=} function to call if the update fails
	 */
	setAll(settings, successCallback, failureCallback) {
		try {
			Object.keys(settings).forEach(k=>{
				this.set(k, settings[k]);
			}, ()=>{if (successCallback) successCallback()})
		} catch (e) {
			if (failureCallback) failureCallback(e);
		}
	},

	/**
	 * Check whether a setting name is valid
	 * @param settingName {string} the setting name
	 * @returns {boolean} true if the setting name is valid
	 * @private
	 */
	_settingsHasDefault(settingName) {
		return Object.keys(DEFAULT_SETTINGS).includes(settingName);
	}

}

export default SettingsService;

import React from 'react'
import './Modal.css'


let DEFAULT_OPTIONS = {
	border: "2px solid #1EA7FD",
	width: "75%"
}

class Modal extends React.Component {

	constructor (props, context) {
		super(props, context)
		this.state = {
			isVisible: props.isVisible
		}
		this.title = props.title;
		this.body = props.body;
		this.styling = props.styling ? {...DEFAULT_OPTIONS, ...props.styling} : DEFAULT_OPTIONS;
		this.onClose = props.onClose;
		this.onOk = props.onOk;
		this.onSave = props.onSave;
	}

	/**
	 * Handle new properties. Merges new properties with existing, overwriting
	 * anything that is already set with the new value.
	 * @param nextProps {Object} new properties
	 * @param nextContext {Object} new context
	 */
	componentWillReceiveProps (nextProps, nextContext) {
		this.setState({
			isVisible: nextProps.isVisible
		});
		this.title = nextProps.title;
		this.body = nextProps.body;
	}

	/**
	 * This callback allows clicking on the modal background to close the modal,
	 * while preventing clicking on the modal itself from closing the modal. It
	 * does this by preventing a click event on the modal from bubbling up.
	 * @param event {Event} the event
	 */
	onClickNotClose(event) {
		event.stopPropagation();
	}

	/**
	 * Callback that closes the modal.
	 * @param event {Event} the event
	 */
	onClickClose(event) {
		this.onClose();
	}

	/**
	 * Callback for when the OK button is clicked (if there is an onOk callback).
	 * @param event {Event} the event
	 */
	onClickOk(event) {
		this.onOk(event);
	}

	/**
	 * Callback for when the SAVE button is clicked (if there is an onSave
	 * callback).
	 * @param event {Event} the event
	 */
	onClickSave(event) {
		this.onSave(event);
	}

	/**
	 * The render callback.
	 * @return {*}
	 */
	render() {
		return(
			<div className="nimbus-modal" style={{visibility: this.state.isVisible ? "visible" : "hidden"}} onClick={this.onClickClose.bind(this)}>
				<div className="nimbus-modal-content" style={this.styling} onClick={this.onClickNotClose.bind(this)}>
					<div className="nimbus-modal-title">
						{this.title}
						<button className={"nimbus-modal-close"} onClick={this.onClickClose.bind(this)}>
							&times;
						</button>
					</div>
					<div className="nimbus-modal-body">
						{this.body}
					</div>
					{
						this.onOk || this.onSave ?
							<div className="nimbus-modal-actions">
								{this.onOk ? <button className="nimbus-modal-action-button" onClick={this.onClickOk.bind(this)}>OK</button> : null}
								{this.onSave ? <button className="nimbus-modal-action-button" onClick={this.onClickSave.bind(this)}>Save</button> : null}
							</div> : null
					}
				</div>
			</div>
		);
	}

}

export default Modal;

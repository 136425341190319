import { createNewFlightDictObject } from './FlightUtils'
import { splitFileIntoLines } from './FileUtil.js'
import { splitDelimitedString } from './StringUtils'
import { parseDatetime } from './DatetimeUtils'

export const DEFAULT_COLUMN_MAP = {
	callsignCol: 0,
	datetimeCol: 1,
	adepCol: 2,
	adesCol: 3,
	aircraftTypeCol: 4,
	latitudeCol: 5,
	longitudeCol: 6,
	altitudeCol: 7
}

/**
 * Parses the result of a FileReader assuming the result is in RAMS External format
 * @param result {string} the contents of a RAMS External file format
 * @return {Object.<string, FlightEntity>} flightDict
 */
export function isaExternalToFlightDict(result) {
	let flightDict = {};
	let lines = splitFileIntoLines(result);
	let columnMap = DEFAULT_COLUMN_MAP;
	let altitudeIsFlightLevel = true;
	lines.forEach((line, idx) => {
		let lineData = splitDelimitedString(line, "\t");
		if (idx === 0 && lineIsHeaderRow(lineData)) {
			altitudeIsFlightLevel = findFlightLevelColumn(lineData) !== null;
			columnMap = findColumnMap(lineData)
		} else {
			let callsign = lineData[columnMap.callsignCol];
			if (callsign && !flightDict.hasOwnProperty(callsign)) {
				flightDict[callsign] = createNewFlightDictObject(callsign);
			}
			let flight = flightDict[callsign];
			if (!flight.icaoType && lineData[columnMap["aircraftTypeCol"]]) {
				flight.icaoType = lineData[columnMap["aircraftTypeCol"]];
			}
			let positionReport = positionReportFromLineData(lineData, columnMap, altitudeIsFlightLevel);
			if (positionReport.latitude != null && positionReport.longitude != null&&
				positionReport.altitude != null && positionReport.datetime != null) {
				flight.positionReports.push([
					positionReport.latitude,
					positionReport.longitude,
					positionReport.altitude,
					positionReport.datetime
				]);
			}
		}
	});
	console.log(flightDict);
	return flightDict;
}

/**
 * Checks a line to see
 * @param row
 * @returns {boolean}
 */
export function lineIsHeaderRow(row) {
	let isHeaderRow = true;
	if (row.length === 0) {
		return false
	}
	row.forEach(value=>{
		if (typeof value !== "string") {
			isHeaderRow = false;
		}
	})
	return isHeaderRow;
}

/**
 * Create a position report from the line data.
 * @param lineData {string[]} the line data
 * @param columnMap {Object.<string, Number>} the column map
 * @param isFlightLevel {boolean} whether the alt value is actual or flight level
 * @return {{altitude: number, datetime: Date, latitude: number, longitude: number}}
 */
export function positionReportFromLineData(lineData, columnMap, isFlightLevel) {
	let latitude = parseFloat(lineData[columnMap["latitudeCol"]]);
	let longitude = parseFloat(lineData[columnMap["longitudeCol"]]);
	let altitude =  parseFloat(lineData[columnMap["altitudeCol"]]);
	if (isFlightLevel) {
		altitude *= 100;
	}
	let datetime = parseDatetime(lineData[columnMap['datetimeCol']]);
	return {
		latitude: latitude,
		longitude: longitude,
		// ** DO NOT AUTO CONVERT FEET TO METERS HERE **
		// The altitude is assumed to be in feet and is used in the calculation
		// of FL in the flight label
		altitude: altitude,
		datetime: datetime,
	}
}

/**
 * Create a column map from a header line.
 * @param headerArray {string[]} the header array
 * @return {{latitudeCol: number, datetimeCol: number, altitudeCol: number, adepCol: number, longitudeCol: number, flightLevelCol: number, adesCol: number, aircraftTypeCol: number, callsignCol: number}}
 */
export function findColumnMap(headerArray) {
	return {
		callsignCol: findCallsignColumn(headerArray) || 0,
		datetimeCol: findDatetimeColumn(headerArray) || 1,
		adepCol: findAdepColumn(headerArray) || 2,
		adesCol: findAdesColumn(headerArray) || 3,
		aircraftTypeCol: findAircraftTypeColumn(headerArray) || 4,
		latitudeCol: findLatitudeColumn(headerArray) || 5,
		longitudeCol: findLongitudeColumn(headerArray) || 6,
		altitudeCol: findFlightLevelColumn(headerArray) || findAltitudeColumn(headerArray) ||  7
	}
}

export function findCallsignColumn(headerArray) {
	let candidateFieldNames = ["CALLSIGN", "FLIGHTID", "FLIGHT_ID", "IDENTIFIER"];
	return findFieldInHeader(headerArray, candidateFieldNames);
}

export function findDatetimeColumn(headerArray) {
	let candidateFieldNames = ["TIMEHHMMSSCOLONS", "date_time_string"];
	return findFieldInHeader(headerArray, candidateFieldNames);
}

export function findLatitudeColumn(headerArray) {
	let candidateFieldNames = ["LAT", "LATITUDE", "LATDEGREES"];
	return findFieldInHeader(headerArray, candidateFieldNames);
}

export function findLongitudeColumn(headerArray) {
	let candidateFieldNames = ["LON", "LONGITUDE", "LONDEGREES"];
	return findFieldInHeader(headerArray, candidateFieldNames);
}

export function findAdepColumn(headerArray) {
	let candidateFieldNames = ["ADEP"];
	return findFieldInHeader(headerArray, candidateFieldNames);
}

export function findAdesColumn(headerArray) {
	let candidateFieldNames = ["ADES"];
	return findFieldInHeader(headerArray, candidateFieldNames);
}

export function findAircraftTypeColumn(headerArray) {
	let candidateFieldNames = ["AIRCRAFT_TYPE", "ICAO", "ICAOCODE", "TYPE"];
	return findFieldInHeader(headerArray, candidateFieldNames);
}

export function findFlightLevelColumn(headerArray) {
	let candidateFieldNames = ["ALTITUDE100FEET", "FLIGHTLEVEL", "flight_level"];
	return findFieldInHeader(headerArray, candidateFieldNames);
}

export function findAltitudeColumn(headerArray) {
	let candidateFieldNames = ["ALTITUDE"];
	return findFieldInHeader(headerArray, candidateFieldNames);
}

/**
 * Returns the position of a column header in a data file. Null if not found
 * @param headerArray {string[]} the header row to look through
 * @param candidateFieldNames {string[]} candidate field names
 * @return {number | null} the index
 */
export function findFieldInHeader(headerArray, candidateFieldNames) {
	let col = null;
	if (headerArray && candidateFieldNames) {
		if (Array.isArray(headerArray) && Array.isArray(candidateFieldNames)) {
			headerArray = headerArray.map(e => {return e.toUpperCase()});
			candidateFieldNames = candidateFieldNames.map(e => {return e.toUpperCase()})
			headerArray.forEach((header, i) => {
				let idx = candidateFieldNames.indexOf(header);
				if (idx >= 0) {
					col = i;
				}
			})
		}
	}
	return col;
}













import {Cartesian2, Math} from 'cesium';
import { createNewFlightDictObject } from './FlightUtils';


export function wvssjToFlightDict(jsonObject) {
	let flightDict = {};
	[...Array(3).keys()].forEach((idx)=>{
		const flightKey = "Track" + idx;
		if (jsonObject.hasOwnProperty(flightKey)) {
			const track = jsonObject[flightKey];
			const callsign = track["Callsign"];
			const aircraftType = track["AircraftType"];
			if (callsign !== "CALLSIGN") {
				if (!flightDict.hasOwnProperty(callsign)) {
					flightDict[callsign] = createNewFlightDictObject(callsign);
				}
				let flight = flightDict[callsign];
				flight.icaoType = aircraftType;
				track["Points"].forEach((p)=>{
					let alt;
					if (p.hasOwnProperty("Altitude")) {
						alt = Number(p["Altitude"]);
					} else {
						alt = Number(p["AltitudeM"]) * 3.28084;
					}
					flight.positionReports.push([
						Number(p["Lat"]),
						Number(p["Long"]),
						alt,
						p["DateTime"].replace(" ", "T") + "Z"
					])
				})
			}
		}
	});
	return  flightDict;
}

export function wvssxToFlightDict(jsonObject) {
	let flightDict = {};

	// Create the tracks
	['track1', 'track2'].forEach((trackName)=>{
		if (jsonObject.hasOwnProperty(trackName)) {
			const track = jsonObject[trackName];
			const callsign = track["callsign"];
			const aircraftType = track["aircraftType"];
			if (!flightDict.hasOwnProperty(callsign)) {
				flightDict[callsign] = createNewFlightDictObject(callsign);
			}
			let flight = flightDict[callsign];
			flight.icaoType = aircraftType;
			flight.wakeReports = jsonObject[trackName]["wakeDisks"] || null;
			track["trackPoints"].forEach((p)=>{
				flight.positionReports.push([
					p["lat"], p["lon"], p["alt"], p["dateTime"]
				])
			})
		}
	});
	return  flightDict;
}

function computeCircle(radius) {
	let positions = [];
	for (let i = 0; i < 360; i++) {
		let radians = Math.toRadians(i);
		positions.push(
			new Cartesian2(
				radius * Math.cos(radians),
				radius * Math.sin(radians)
			)
		);
	}
	return positions;
}






import React from 'react'
import 'react-picky/dist/picky.css';
import {
	BingMapsImageryProvider, Cesium3DTileset,
	createWorldTerrain, Ion, IonResource, Viewer
} from 'cesium'
import './Map.css'
import MapContents from '../../model/MapContents'
import MapClickHandler from '../../model/MapClickHandler'
import { isEmpty } from '../../utilities/BooleanLogic'
import '../../../node_modules/cesium/Source/Widgets/widgets.css'
import MapCameraManager from "../../model/MapCameraManager";
import Graph from "../Nimbus/overlays/graph/Graph";
import Graph2 from "../Nimbus/overlays/graph2/Graph2";
import SettingsService from "../../services/SettingsService";

const BING_MAPS_URL = "//dev.virtualearth.net";
const BING_MAPS_KEY = "Av2Gv9bFjyQlsU1Gg330OpcOT_UnFBsYRjTCAcLi_xCBGXHeJiJkHx2YO7ZYo77M";
const CESIUM_ACCESS_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJ" +
	"lY2I2YTVmYy03YmIyLTQ5ZDctODA3Yy1lOWVhYTZhOWQ1MzEiLCJpZCI6MzU2OSwic2NvcGVzI" +
	"jpbImFzbCIsImFzciIsImFzdyIsImdjIl0sImlhdCI6MTUzODA5OTAxM30.ddhTRXVQ3gQETJZ" +
	"TNvhFhH7WaW0l5FzxXtwbYCXZSKg";


class Map extends React.Component {

	/**
	 * Component constructor.
	 * @param props {Object} properties
	 * @param props.scenario {NimbusScenario} the Nimbus scenario object
	 * @param context {Object} context
	 */
	constructor (props, context) {
		super(props, context);
		this.state = {
			isLoading: false,
			scenario: props.scenario,
			selectedFlt: [],
			currentTime: null
		}
		this.onLeftClick = this.onLeftClick.bind(this)
	}

	/**
	 * Callback fired once the component has mounted.
	 */
	componentDidMount () {
		Ion.defaultAccessToken = CESIUM_ACCESS_TOKEN;
		const imageryProvider = new BingMapsImageryProvider({url : BING_MAPS_URL, key : BING_MAPS_KEY,});
		const terrainProvider = createWorldTerrain({requestWaterMask : true, requestVertexNormals : true});
		this.viewer = new Viewer(this.mapContainer, {
			animation : true,
			baseLayerPicker : true,
			fullscreenButton : true,
			fullscreenElement: "nimbus-container",
			geocoder : false,
			homeButton : false,
			infoBox : false,
			sceneModePicker : true,
			selectionIndicator : true,
			timeline : true,
			navigationHelpButton : true,
			scene3DOnly : false,
			imageryProvider: imageryProvider,
			terrainProvider: terrainProvider,
		});
		this.viewer.getFlightByIdentifier = this.getFlightByIdentifier;

		// Adds support for automated rendering of 3D buildings
		if (SettingsService.get("show3dBuildings")) {
			this.viewer.scene.primitives.add(new Cesium3DTileset({url: IonResource.fromAssetId(96188)}));
		}

		this.viewer.selectedFlights = this.state.scenario.selectedFlights ? this.state.scenario.selectedFlights : [];
		this.setState({
			isLoading : true
		});
	}


	/**
	 * Handler that watches for and acts on updated properties. Specifically this
	 * watches for changes in the scenario object and acts accordingly
	 * @param nextProps {Object} updated properties
	 * @param nextContext {Object} updated context
	 */
	componentWillReceiveProps (nextProps, nextContext) {
		this.viewer.entities.removeAll();
		this.state.selectedFlt = [];
		this.viewer.selectedFlights = [];
		this.setState({
			isLoading: true,
			scenario: nextProps.scenario
		})
	}

	/**
	 * Handler that will ensure the viewer is destroyed when the component is
	 * unmounted.
	 */
	componentWillUnmount () {
		if(this.viewer) {
			this.viewer.destroy();
		}
	}


	/**
	 * Return a flight from the viewer by its ID.
	 * @param identifier {String} the identifier
	 * @return {cesium.Entity|null} the flight
	 */
	getFlightByIdentifier(identifier) {
		let entity = null;
		this.entities.values.forEach(function(e) {
			if (e.identifier === identifier) {
				entity = e;
			}
		});
		return entity;
	}

	// ======================================================================= //
	// 				    			Action Handlers                              //
	// ======================================================================= //
	/**
	 * Handles left click behavior when clicking on the Cesium globe.
	 * It takes the position of the click and establishes if anything under that click
	 * is an object.
	 * @param click
	 */
	onLeftClick(click) {
		if (isEmpty(this.viewer.selectedFlights)) {
			this.viewer.selectedFlights = [];
		}
		const pickedObject = this.viewer.scene.pick(click.position);
		if (pickedObject !== undefined && pickedObject.mesh !== undefined) {
			if (pickedObject.id._selected === true) {
				this.state.selectedFlt = this.state.selectedFlt.filter(f => f !== pickedObject.id);
			} else if (pickedObject.id._selected === false) {
				this.state.selectedFlt.push(pickedObject.id)
			}
			let flight = pickedObject.id;
			if (flight._selected) {
				flight.isSelected(false);
				this.viewer.selectedFlights = this.viewer.selectedFlights.filter(f => f !== flight.identifier)
			} else {
				flight.isSelected(true);
				this.viewer.selectedFlights.push(flight.identifier);
			}
		}

		if (this.state.selectedFlt.length === 3) {
			this.state.selectedFlt[0].isSelected(false);
			this.state.selectedFlt[1].isSelected(false);
			this.state.selectedFlt[2].isSelected(true);
			this.state.selectedFlt = [this.state.selectedFlt[2]];
		}

		if (this.state.selectedFlt.length === 2) {
			this.state.selectedFlt[0].addElasticBand(this.viewer, this.state.selectedFlt[0], this.state.selectedFlt[1]);
		} else {
			if (this.state.selectedFlt.length !== 0) {
				this.viewer.entities.removeById("elasticBandLabel");
				this.viewer.selectedFlights.forEach(flightId => {
					this.viewer.getFlightByIdentifier(flightId).deleteElasticBand()
				})
			}
		}

		if (this.state.selectedFlt.length === 1) {
			this.state.selectedFlt[0].deleteElasticBand();
			this.viewer.entities.removeById("elasticBandLabel");
		}
	}


	handleDropDownZoom(flightId){
		let flightEntity = this.viewer.getFlightByIdentifier(flightId);
		this.viewer.flyTo(flightEntity).then(function(){
			this.viewer.trackedEntity = flightEntity;
		});
	}

	/**
	 * Function generates all of the content that will be shown within the map.
	 * Everything is contained within a <span> tag so that a single DOM element
	 * is returned, which is preferred by React.
	 * @return {Element}
	 */
	renderContents() {
		const {isLoading} = this.state;
		let contents = null;
		if(isLoading) {
			const {scene} = this.viewer;
			const {flyToLocation, flightData, selectedFlights, settings, timelineStart, options} = this.state.scenario;
			this.viewer.selectedFlights = selectedFlights;
			contents = (
				<span>
					<MapContents
						viewer={this.viewer}
						flightData={flightData}
						timelineStart={timelineStart}
						settings={settings}
						options={options} />
					<MapClickHandler
						viewer={this.viewer}
						onLeftClick={this.onLeftClick.bind(this)} />
					<MapCameraManager
						camera={scene.camera}
						flyToLocation={flyToLocation}/>
				</span>
			);
		}
		return contents;
	}

	/**
	 * The main render callback.
	 * @return {*}
	 */
	render() {
		const contents = this.renderContents();
		return (
			<div style={{height: "100%"}}>
				<div style={{ position:'absolute', zIndex:'100', left:'5px', top:'40px', width: '200px', fontSize: '12px', opacity: '0.95'}}>
				</div>
				<div className="mapWidget" ref={ element => this.mapContainer = element }>
					{contents}
					<div id={"verticalWindow"}>
						{ this.state.verticalWindowData2 ? <Graph2 graph_data1={this.state.verticalWindowData1} graph_data2={this.state.verticalWindowData2} current_time1={this.state.currentTime} current_time2={this.state.currentTime} flight_id1={this.state.verticalWindowDataCallsign1} flight_id2={this.state.verticalWindowDataCallsign2}/> : this.state.verticalWindowData1 ? <Graph graph_data={this.state.verticalWindowData1} current_time={this.state.currentTime} flight_id={this.state.verticalWindowDataCallsign1}/> : null }
					</div>
				</div>
			</div>
		);
	}
}

export default Map;

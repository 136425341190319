/**
 * Handles a file opened dialog or a file drag and drop. Once the file is read, an event is sent
 * to the provided callback. The file contents is available as e.target.result
 *
 * Example:
 *
 *      function fileToConsole(e) {
 *          console.log(e.target.result);
 *      }
 *      parseSingleFile(myFile, fileToConsole);
 *
 * @param file {File} the file to be read
 * @param onLoadEnd {function} the function to be performed once the file is read
 */
export function parseSingleFile(file, onLoadEnd) {
    let fileReader = new FileReader();
    const fileExtension = file.name.split(".").pop().toLowerCase();
    fileReader.onload = function(e) {
    	onLoadEnd(e.target.result, fileExtension);
		}
    fileReader.readAsText(file);
}

/**
 * Break a text file down into an array with one line per entry
 * @param contents {string} the contents to split
 * @return {string[]}
 */
export function splitFileIntoLines(contents) {
	if (contents) {
		let lines = contents.split(/\r?\n/);
		return lines.filter((line) => {return line !== ""})
	}
	return [];
}

/**
 * will evaluate to true if value is not:
 *   null
 *   undefined
 *   NaN
 *   empty string ("")
 *   0
 *   false
 * @param value the value
 * @return {boolean} result
 */
export function isEmpty(value) {
	return !value && value !== 0;
}

/**
 * Determine whether or not a string is valid JSON or not
 * @param str {string} to be parsed
 * @return {boolean} true if valid JSON
 */
export function isJson(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

/**
 * Used to detect if a value is undefined:
 * - true if value is undefined
 * - false if value is anything else including null
 * @param value {*} the value to review
 * @return {boolean} the result
 */
export function isUndefined(value) {
	return value === undefined;
}

import moment from 'moment'

/**
 * Creates a new flight object for use in building flight dictionaries from raw data
 * @param aircraftId {String} the aircraft ID
 * @return {{startDatetime: (string|null), positionReports: [], identifier: string, aircraftType: (string|null), endDatetime: (string|null)}}
 */
export function createNewFlightDictObject(aircraftId) {
    if (typeof aircraftId !== "string") {
        throw "Aircraft ID must be of type string";
    }
    aircraftId = aircraftId.trim();
    if (aircraftId === "") {
        throw "Aircraft ID cannot be blank";
    }
    return {
        identifier: aircraftId,
        icaoType: null,
        startDatetime: null,
        endDatetime: null,
        positionReports: [],
		wakeReports: null
    };
}

/**
 * Finds the minimum and maximum datetimes in a flight dict.
 * @param flightDict {Object} the flight dict
 * @returns {{min: string|null, max: string|null, latestCallsign: null, earliestCallsign: null}}
 */
export function findDatetimeLimits(flightDict) {
    if (typeof flightDict !== "object" || flightDict === null) {
        throw "flightDict must be an object";
    }
    let limits = {
        earliestCallsign: null,
        latestCallsign: null,
        min: null,
        max: null
    };
    for (let callsign in flightDict) {
        if (flightDict.hasOwnProperty(callsign)) {
            let flight = flightDict[callsign];
            if (flight.startDatetime === null || flight.endDatetime === null) {
                console.warn('flights have null start and end datetime.');
            }
            // Check for new minimum value
            if (limits.min === null || moment(limits.min).isAfter(moment(flight.startDatetime))) {
                limits.min = flight.startDatetime;
                limits.earliestCallsign = callsign;
            }
            // Check for new maximum value
            if (limits.max === null || moment(limits.max).isBefore(moment(flight.endDatetime))) {
                limits.max = flight.endDatetime;
                limits.latestCallsign = callsign;
            }
        }
    }
    return limits;
}

/**
 * Filters a flight dict based on a list of callsigns.
 * @param flightDict {Object} the flight dict
 * @param callsignList {Array<string>} an array of requested callsigns
 */
export function filterFlightDictByCallsignList(flightDict, callsignList) {
    if (typeof flightDict !== 'object' || flightDict === null) {
        throw "flightDict must be an object";
    }
    if (!Array.isArray(callsignList)) {
        throw "callsignList must be an array";
    }
    let newDict = {};
    for (let callsign in flightDict) {
        if (flightDict.hasOwnProperty(callsign)) {
            if (callsignList.includes(callsign)) {
                newDict[callsign] = flightDict[callsign];
            }
        }
    }
    return newDict;
}

/**
 * Filters a flight dict based on a minimum and maximum datetime.
 * @param flightDict {Object} the flight dict
 * @param minDatetime {string|moment.Moment} the minimum datetime
 * @param maxDatetime {string|moment.Moment} the maximum datetime
 */
export function filterFlightDictByDatetime(flightDict, minDatetime, maxDatetime) {
    if (typeof minDatetime === "string"){minDatetime = moment(minDatetime);}
    if (typeof maxDatetime === "string"){maxDatetime = moment(maxDatetime);}
    let newDict = {};
    for (let callsign in flightDict) {
        if (flightDict.hasOwnProperty(callsign)) {
            let flight = flightDict[callsign];
            let newFlight = [];
            for (let pointIdx in flight) {
                if (flight.hasOwnProperty(pointIdx)) {
                    let point = flight[pointIdx];
                    let datetime = moment(point.datetime);
                    if (datetime.isAfter(minDatetime) && datetime.isBefore(maxDatetime)) {
                        newFlight.push(point);
                    }
                }
            }
            if (newFlight.length > 0) {
                newDict[callsign] = newFlight;
            }
        }
    }
    return newDict;
}



import React from 'react';
import './App.css';
import Nimbus from "nimbus-library/src/components/Nimbus";

function App() {
  return (
    <div className="App" style={{padding: "0", backgroundColor: "black"}}>
		<Nimbus />
    </div>
  );
}

export default App;

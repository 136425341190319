/**
 * This is a shallow comparison of objects. This checks if all of the keys in
 * B are also present in object A. This is useful when you are working with an
 * object and simply want to ckeck top-level attributes, not nested fields or
 * sizes of values.
 *
 * @param objA {Object} the first object
 * @param objB {Object} the second object
 * @return {boolean} true if object A and B are the same
 */
export function shallowEquals(objA, objB) {
	// If deep equal is true, return true
	if (objA === objB) return true;

	// Start looking at the keys
	const aKeys = Object.keys(objA);
	const bKeys = Object.keys(objB);

	// Reject if they are different lengths
	if (bKeys.length !== aKeys.length) {
		return false;
	}

	// Compare the keys. All the keys in A should be in B
	for (let i = 0; i < aKeys.length; i++) {
		if (objA[aKeys[i]] !== objB[aKeys[i]]) {
			return false;
		}
	}

	return true;
}

/**
 * Calculates whether two points represent the same position. This is a useful
 * check ahead of any angle between calculations since two identical points
 * would return a zero value and result in a divide by zero error that shows
 * as 'DeveloperError: normalized result is not a number'
 *
 * @param a {Cartographic} the first position
 * @param b {Cartographic} the second position
 * @return {boolean}
 */
export function isPositionEqual(a, b) {
	return a.latitude === b.latitude &&
		a.longitude === b.longitude &&
		a.height === b.height;
}

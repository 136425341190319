import React from 'react'
import Map from '../Map/Map'
import Toolbar from './overlays/toolbar/Toolbar'
import logo from '../../Assets/img/logo.png';
import Name from './overlays/name/Name'
import Description from './overlays/description/Description'
import Loading from './overlays/loading/Loading'
import './Nimbus.css'

import "./NimbusTypedef";
import SettingsService from "../../services/SettingsService";

class Nimbus extends React.Component {

	/**
	 * The Nimbus component constructor.
	 * @param nimbusScenario {NimbusScenario} constructor properties
	 * @param context {Object} component context
	 */
	constructor (nimbusScenario, context) {
		super(nimbusScenario, context);
		this.state = {
			scenario: {
				annotations: nimbusScenario.annotations ? nimbusScenario.annotations : null,
				cameraMotions: nimbusScenario.cameraMotions ? nimbusScenario.cameraMotions : null,
				description: nimbusScenario.description ? nimbusScenario.description : null,
				features: nimbusScenario.features ? nimbusScenario.features : null,
				flightData: nimbusScenario.flightData ? nimbusScenario.flightData : null,
				flyToLocation: nimbusScenario.flyToLocation ? nimbusScenario.flyToLocation : null,
				name: nimbusScenario.name ? nimbusScenario.name : null,
				timelineStart: nimbusScenario.timelineStart ? nimbusScenario.timelineStart : null,
				selectedFlights: nimbusScenario.selectedFlights ? nimbusScenario.selectedFlights : null,
				url: nimbusScenario.url ? nimbusScenario.url : null,

				// Scenario state flags
				isLoading: false,
				settings: SettingsService.getAll(),

				// Callbacks passed down to child components
				onUpdateScenario: this.onUpdateScenario.bind(this),
				onUpdateSetting: this.onUpdateSetting.bind(this)
			}
		}
	}

	/**
	 * Update the component if it receives new properties
	 * @param nextNimbusScenario {NimbusScenario} the scenario
	 * @param nextContext {Object=} optional context
	 */
	componentWillReceiveProps(nextNimbusScenario, nextContext) {
		if (this.state.scenario !== nextNimbusScenario) {
			this.setState({
				scenario: {...this.state.scenario, nextNimbusScenario}
			});
		}
	}

	/**
	 * This callback is sent down to all child components. It allows child
	 * components to make changes to the scenario, and have those changes
	 * recognized by other child components.
	 * @param updatedScenarioProperties {Object} the Nimbus scenario object
	 * @param callback {Function=} any callback to be actioned after the update
	 */
	onUpdateScenario(updatedScenarioProperties, callback) {
		let updatedScenario = {...this.state.scenario, ...updatedScenarioProperties};
		this.setState({
			scenario: updatedScenario
		}, () => {
			if (callback) callback()
		})
	}

	/**
	 * There has been a settings change that we want propagated down to child
	 * components. Settings are usually changed via the settings toolbar, which
	 * in turn opens the SettingsDialog. But there is also right click
	 * functionality that we will need to add into the map eventually
	 * @param {String} key: the setting key
	 * @param {any} value: the setting value
	 */
	onUpdateSetting(key, value) {
		SettingsService.set(key, value, (settings)=> {
			let scenario = this.state.scenario;
			scenario.settings = settings;
			this.setState({
				scenario: scenario
			});
		});
	}

	/**
	 * The main render callback for the component.
	 * @return {ReactDOM} DOM element
	 */
	render() {
		return(
			<div id="nimbus-container">
				<Description text={this.state.scenario.description} url={this.state.scenario.url} />
				<Map scenario={this.state.scenario} />
				<Name text={this.state.scenario.name} />
				<Toolbar scenario={this.state.scenario} />
				<Loading isVisible={this.state.scenario.isLoading} />
				<img id="isa-logo" src={logo} alt="logo.png"/>
			</div>
		);
	}

}

export default Nimbus;

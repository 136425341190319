import {
	CallbackProperty,
	Cartesian2,
	Cartesian3,
	Cartographic,
	Color,
	ColorMaterialProperty,
	EllipsoidGeodesic,
	Entity,
	ExtrapolationType,
	HeadingPitchRoll,
	HermitePolynomialApproximation,
	HorizontalOrigin,
	JulianDate,
	LabelGraphics,
	LabelStyle,
	PathGraphics,
	PolylineDashMaterialProperty,
	PolylineGlowMaterialProperty,
	PositionPropertyArray,
	ReferenceProperty,
	SampledPositionProperty,
	SampledProperty,
	TimeInterval,
	TimeIntervalCollection,
	TimeIntervalCollectionProperty,
	Transforms,
	VelocityOrientationProperty,
	VerticalOrigin
} from 'cesium'

import {Math as CesiumMath} from 'cesium'

import { modelResolver } from '../utilities/ModelResolver'
import { isEmpty } from '../utilities/BooleanLogic'
import { isPositionEqual } from '../utilities/Comparators'
import { padFlightLevel } from '../utilities/StringUtils'
import { computeWakeRectangleHierarchy, createWakeEntity } from "../utilities/GeometryUtils";

/**
 * Cesium expects an Entity object. The createFlightEntity function creates an Entity
 * and then 'extends' the object with a lot of flight-specific properties that
 * are used by Nimbus.
 *
 * @param identifier {String} the flight identifier
 * @param opts {Object} flight options
 * @param opts.minimumPixelSize {Number} minimum number of pixels for the sprite
 * @param opts.flightLabelFontSize {Number} the size of the font to use
 * @param opts.icaoType {String} flight ICAO code
 * @param opts.isGhost {boolean} true if the flight is a ghost
 * @param opts.pathLeadTime {Number} seconds ahead to draw the flight path
 * @param opts.pathTrailTime {Number} seconds behind to draw the flight path
 * @param opts.pathColor {String} the color to draw the flight path
 * @param opts.pathWidth {Number} the width to draw the flight path
 * @param opts.silhouetteAlpha {Number} the aircraft silhouette size
 * @param opts.silhouetteSize {Number} the silhouette transparency
 * @return {FlightEntity} the flight
 */
export function createFlightEntity(identifier, opts) {
	let flight = new Entity();
	let modelFilePath = modelResolver(opts.icaoType);
	flight.identifier = identifier;
	flight.name = identifier;
	flight.icaoType = (opts ? (opts.icaoType ? opts.icaoType : 'UKN') : 'UKN');
	flight.model = {uri: modelFilePath, minimumPixelSize: (opts ? (opts.minimumPixelSize ? opts.minimumPixelSize : 24) : 'UKN')};
	flight.position = new SampledPositionProperty();
	flight.position.setInterpolationOptions({
		interpolationDegree : 2,
		interpolationAlgorithm : HermitePolynomialApproximation
	});
	flight.wakeDisks = [];

	// Flight internal properties
	flight._flightEndDatetime = null;
	flight._flightStartDatetime = null;
	flight._glbFile = modelFilePath;
	flight._linkedEntities = [];
	flight._timeIntervalFlightLevel = null;
	flight._timeIntervalHeading = null;
	flight._timeIntervalPosition = null;
	flight._timeIntervalSpeed = null;
	flight._rawPositionData = [];
	flight._ghost = !!opts.isGhost;
	flight._selected = false;

	flight._pathLeadTime = opts ? (opts.pathLeadTime ? opts.pathLeadTime : 60) : 60;
	flight._pathTrailTime = opts ? (opts.pathTrailTime ? opts.pathTrailTime : 360) : 360;
	flight._pathColor = opts ? (opts.pathColor ? opts.pathColor : "YELLOW") : "YELLOW";
	flight._pathWidth = opts ? (opts.pathWidth ? opts.pathWidth : 2) : 2;

	/**
	 * Add a new position report point to the flight. This will automatically
	 * update the flight's start and end time
	 * @param lat {Number} latitude
	 * @param lon {Number} longitude
	 * @param alt {Number} altitude
	 * @param datetime {Date|String} the position report datetime
	 */
	flight.addPoint = function(lat, lon, alt, datetime) {
		try {
			lat = Number(lat);
			lon = Number(lon);
			alt = Number(alt);
			if (typeof datetime === "string") {
				datetime = new Date(datetime);
			}
			if (lat > 90 || lat < -90) {
				console.error("Latitude is out of bounds: ", lat);
				return;
			}
			if (lon > 180 || lon < -180) {
				console.error("Longitude is out of bounds: ", lat);
				return;
			}
			if (alt < 0 || isEmpty(alt)) {
				console.error("Altitude must be positive number: ", alt, flight.identifier);
				return;
			}
			// NOTE: Cartesian3.fromDegrees() is X,Y,Z so use Lon, Lat, Alt
			flight.position.addSample(JulianDate.fromDate(datetime), Cartesian3.fromDegrees(lon, lat, alt));
			flight._rawPositionData.push([lat, lon, alt, datetime]);
			flight._updateFlightStartDatetime(datetime);
			flight._updateFlightEndDatetime(datetime);
		} catch (err) {
			console.error("Invalid point data: ", lat, lon, alt, datetime, err);
		}
	};

	/**
	 * Add a list of [lat, lon, alt, datetime] points
	 * @param pointList {PositionReport[]} the array of points
	 */
	flight.addPointsFromList = function (pointList) {
		if (!isEmpty(pointList)) {
			pointList.forEach(p => {
				if (!isEmpty(p)) {
					this.addPoint(p[0], p[1], p[2], p[3]);
				}
			});
		}
		flight.updateFlight();
	};

	/**
	 * Add an elastic band between this flight and another selected flight
	 * @param viewer {Viewer} the Cesium viewer
	 * @param flightOne {Entity} the first flight
	 * @param flightTwo {Entity} the second flight
	 */
	flight.addElasticBand = function(viewer, flightOne, flightTwo) {
		viewer.entities.removeById("elasticBandLabel");
		let fromPos = this._getFlightReferenceProperty(viewer, ["position"]);
		let toPos = flightTwo._getFlightReferenceProperty(viewer, ["position"]);
		// noinspection JSCheckFunctionSignatures
		flight.polyline = {
			followSurface: false,
			positions: new PositionPropertyArray([fromPos, toPos]),
			material: new ColorMaterialProperty(Color.BLUE.withAlpha( 1.0 ))
		};
		const getMidPoint = function(time) {
			try {
				let flightOnePos = flightOne.position.getValue(time);
				let flightTwoPos = flightTwo.position.getValue(time);
				let start = Cartographic.fromCartesian(flightOnePos);
				let end = Cartographic.fromCartesian(flightTwoPos);
				let geodesic = new EllipsoidGeodesic();
				geodesic.setEndPoints(start, end);
				let midpointCartographic = geodesic.interpolateUsingFraction(0.5, new Cartographic());
				let height = (start.height + end.height) / 2;
				return Cartesian3.fromRadians(midpointCartographic.longitude, midpointCartographic.latitude, height);
			} catch(err) {
				viewer.entities.removeById("elasticBandLabel");
			}
		};
		const getLength = function(time) {
			try {
				const flightOneConst = flightOne;
				const flightTwoConst = flightTwo;
				let flightOnePos = flightOneConst.position.getValue(time);
				let flightTwoPos = flightTwoConst.position.getValue(time);
				let start = Cartographic.fromCartesian(flightOnePos);
				let end = Cartographic.fromCartesian(flightTwoPos);
				let geodesic = new EllipsoidGeodesic();
				geodesic.setEndPoints(start, end);
				let lengthInMeters = Math.round(parseFloat(geodesic.surfaceDistance));
				let flOne = flightOne._timeIntervalFlightLevel.getValue(time);
				let flTwo = flightTwo._timeIntervalFlightLevel.getValue(time);
				let vertical = (flOne - flTwo) * 100;
				if (vertical < 0) vertical = vertical * -1;  // Absolute value. DO NOT USE Math.abs()
				let label =  (lengthInMeters * 0.000539957).toFixed(1) + " nm | " + vertical.toFixed(0) + " ft";
				return label;
			} catch(err) {
				console.error(err);
				viewer.entities.removeById("elasticBandLabel");
			}
		};
		viewer.entities.add({
			id: "elasticBandLabel",
			position : new CallbackProperty(getMidPoint, false),
			label : {
				text: new CallbackProperty(getLength, false),
				font : (opts ? (opts.flightLabelFontSize ? opts.flightLabelFontSize : 11) : 11) + 'px sans-serif',
				pixelOffset : new Cartesian2(-60, 0)
			}
		});
	}

	/**
	 * Flight is selected. Make it so
	 * @param value {boolean} whether the flight is selected
	 */
	flight.isSelected = function(value) {
		if (value === true) {
			flight._selected = true;
			// noinspection JSValidateTypes not an issue
			flight.model.silhouetteColor = Color.RED;
			// noinspection JSValidateTypes not an issue
			flight.model.silhouetteSize = opts ? (opts.silhouetteSize ? opts.silhouetteSize : 2) : 2;
			flight.model.silhouetteAlpha = opts ? (opts.silhouetteAlpha ? opts.silhouetteAlpha : 0.2) : 0.2;
		} else {
			if (flight._ghost) {
				flight.isGhost(true);
			} else {
				flight._selected = false;
				flight.model.silhouetteColor = null;
				flight.model.silhouetteSize = null;
				flight.model.silhouetteAlpha = null;
				flight.deleteElasticBand();
			}
		}
	};

	flight.isGhost = function(value) {
		if (value === true) {
			flight._ghost = true;
			flight.model.silhouetteColor = Color.BLUE;
			// noinspection JSValidateTypes not an issue
			flight.model.silhouetteSize = opts ? (opts.silhouetteSize ? opts.silhouetteSize : 2) : 2;
			flight.model.silhouetteAlpha = opts ? (opts.silhouetteAlpha ? opts.silhouetteAlpha : 0.15) : 0.15;
		} else {
			flight._ghost = false;
			flight.model.silhouetteColor = null;
			flight.model.silhouetteSize = null;
			flight.model.silhouetteAlpha = null;
		}
	};

	flight.isWakeEmitter = function(value, wakeReports) {

		if (value === true && wakeReports != null) {
			Object.keys(wakeReports).forEach((wakeKey)=>{
				let wakeDisk = wakeReports[wakeKey];

				let leftWakeEntity = createWakeEntity();
				let rightWakeEntity = createWakeEntity();

				wakeDisk["positions"].forEach((p)=>{
					let datetime = JulianDate.fromIso8601(p["dateTime"]);
					let lp1 = Cartesian3.fromDegrees(p["lon1"], p["lat1"], p["alt1"]);
					let rp1 = Cartesian3.fromDegrees(p["lon2"], p["lat2"], p["alt2"]);
					if (leftWakeEntity.orientation == null) {
						let orientation = Transforms.headingPitchRollQuaternion(
							lp1,
							new HeadingPitchRoll(
								CesiumMath.toRadians(wakeDisk["rotation"]),
								CesiumMath.toRadians(0.0),
								CesiumMath.toRadians(90.0)
							)
						)
						leftWakeEntity.orientation = orientation;
						rightWakeEntity.orientation = orientation;
						let h = computeWakeRectangleHierarchy({
							lat: p["lat1"],
							lon: p["lon1"],
							alt: p["alt1"]
						}, 90, 500000);
						leftWakeEntity.polygon = {
							hierarchy: h,
							perPositionHeight: true,
							material: "../Assets/img/smoke.png",
							outlineColor: Color.BLACK,
						}
					}
					leftWakeEntity.position.addSample(datetime, lp1);
					leftWakeEntity.cylinder.topRadius.addSample(datetime, p["vSize"]);
					leftWakeEntity.cylinder.bottomRadius.addSample(datetime, p["vSize"]);
					rightWakeEntity.position.addSample(datetime, rp1);
					rightWakeEntity.cylinder.topRadius.addSample(datetime, p["vSize"]);
					rightWakeEntity.cylinder.bottomRadius.addSample(datetime, p["vSize"]);

				});

				flight.wakeDisks.push(leftWakeEntity)
				flight.wakeDisks.push(rightWakeEntity)
			})
		}
	}

	/**
	 * Delete any elastic band (polyline) associated with the flight.
	 */
	flight.deleteElasticBand = function() {
		flight.polyline = null;
	};

	/**
	 * Rebuild the position array from raw data, removing everything that is after datetime
	 * @param datetime {Date|string}
	 */
	flight.trimPositionAfter = function (datetime) {
		if (typeof datetime === "string") {
			datetime = new Date(datetime);
		}
		let newPosition = new SampledPositionProperty();
		let newRawPositionData = [];
		flight._getFlightRawData().forEach(p => {
			let lat = p[0];
			let lon = p[1];
			let alt = p[2];
			let dt = p[3];
			if (i < 2) {
				// Ensures we always get at least 2 points
				newPosition.addSample(JulianDate.fromDate(dt), Cartesian3.fromDegrees(lon, lat, alt));
				newRawPositionData.push([lat, lon, alt, dt]);
			} else if (dt.getTime() < datetime.getTime()) {
				newPosition.addSample(JulianDate.fromDate(dt), Cartesian3.fromDegrees(lon, lat, alt));
				newRawPositionData.push([lat, lon, alt, dt]);
			}
		});
		flight.position = newPosition;
		flight._rawPositionData = newRawPositionData;
		flight.updateFlight();
	};

	/**
	 * Rebuild the position array from raw data, removing everything that is before datetime
	 * @param datetime {Date|string}
	 */
	flight.trimPositionBefore = function (datetime) {
		if (typeof datetime === "string") {
			datetime = new Date(datetime);
		}
		let newPosition = new SampledPositionProperty();
		let newRawPositionData = [];
		flight._getFlightRawData().forEach(p => {
			let lat = p[0];
			let lon = p[1];
			let alt = p[2];
			let dt = p[3];
			if (dt.getTime() > datetime.getTime()) {
				newPosition.addSample(JulianDate.fromDate(dt), Cartesian3.fromDegrees(lon, lat, alt));
				newRawPositionData.push([lat, lon, alt, dt]);
			}
		});
		flight.position = newPosition;
		flight._rawPositionData = newRawPositionData;
		flight.update();
	};

	/**
	 * Opt to extrapolate the flight's path for N seconds once the data ends
	 * @param seconds {number}
	 */
	flight.setExtrapolateForwards = function(seconds) {
		this.position.forwardExtrapolationType = ExtrapolationType.EXTRAPOLATE;
		let newEndTime = JulianDate.addSeconds(this._flightEndDatetime, seconds, new JulianDate());
		let cart3 = this.position.getValue(newEndTime, new Cartesian3());
		if (typeof cart3 !== "undefined") {
			let newEndPos = Cartographic.fromCartesian(cart3);
			this.addPoint(newEndPos.latitude*57.2958, newEndPos.longitude*57.2958,
				newEndPos.height, JulianDate.toDate(newEndTime));
			this.path = new PathGraphics({
				resolution: 1,
				leadTime: opts ? (opts.pathLeadTime ? opts.pathLeadTime : 60) : 60,
				trailTime: opts ? (opts.pathTrailTime ? opts.pathTrailTime : 360) : 360,
				material: new PolylineDashMaterialProperty({
					color: Color["BLUE"],
					dashLength: 4.0
				}),
				width: 4
			});
			this._updateFlightTimeIntervalHeading();
			this._updateFlightTimeIntervalFlightLevel();
			this._updateFlightTimeIntervalSpeed();
		} else {
			console.log("unable to extrapolate flight point for track");
		}
	};

	/**
	 * Returns the flight duration in seconds
	 * @return {number} duration of flight in seconds
	 * @private
	 */
	flight._getFlightDuration = function() {
		return JulianDate.secondsDifference(flight._flightEndDatetime, flight._flightStartDatetime);
	};

	/**
	 * Gets the raw data for the flight ordered by datetime.
	 * @return {Array.<Number,Number,Number,Date>}
	 */
	flight._getFlightRawData = function() {
		return flight._rawPositionData.sort(function(a, b){return a[3].getTime()-b[3].getTime();});
	};

	/**
	 * Get a reference property for this flight. Useful in referencing the
	 * position of entity by another entity.
	 * @param viewer {Viewer} the viewer
	 * @param properties {Array} e.g. ["position"] or ["label", "font"]
	 * @return {ReferenceProperty}
	 */
	flight._getFlightReferenceProperty = function(viewer, properties) {
		return new ReferenceProperty(viewer.entities, flight.id, properties);
	};

	/**
	 * Generic update that runs a few dependent functions.
	 */
	flight.updateFlight = function () {
		flight._updateFlightAvailability();
		flight._updateFlightOrientation();
		flight._updateFlightPath(flight._pathLeadTime, flight._pathTrailTime, flight._pathColor, flight._pathWidth);
		flight._updateFlightTimeIntervalHeading();
		flight._updateFlightTimeIntervalFlightLevel();
		flight._updateFlightTimeIntervalPosition();
		flight._updateFlightTimeIntervalSpeed();
		flight._updateFlightLabel();
		flight._updateFlightWall({isVisible: true});
	};

	/**
	 * Set the availability of the flight based on the currently set start and
	 * end datetime.
	 * @private
	 */
	flight._updateFlightAvailability = function () {
		flight.availabilty = new TimeIntervalCollection([
			new TimeInterval({
				start: flight._flightStartDatetime,
				stop: flight._flightEndDatetime
			})
		]);
	};

	/**
	 * Used when a new point is added to ensure we have an availability value that is the complete
	 * duration of the flight. This can be manually overridden using setFlightEndDatetime()
	 * @param datetime {string|Date}
	 * @private
	 */
	flight._updateFlightEndDatetime = function (datetime) {
		if (typeof datetime === "string") {
			datetime = new Date(datetime);
		}
		try {
			let julianDatetime = JulianDate.fromDate(datetime);
			if (flight._flightEndDatetime === null || julianDatetime > flight._flightEndDatetime) {
				flight._flightEndDatetime = julianDatetime;
			}
		} catch (err) {
			/*console.log(err);*/
		}
	};

	/**
	 * Update the label of the flight.
	 * @private
	 */
	flight._updateFlightLabel = function () {
		// Merge Flight Level and Speed time interval collections
		let labelText = new TimeIntervalCollectionProperty();
		let intervalStart = JulianDate.clone(flight._flightStartDatetime, new JulianDate());
		for (let x = 0; x < flight._getFlightDuration(); x += 3) {
			let intervalStop = JulianDate.addSeconds(flight._flightStartDatetime, x, new JulianDate());
			let flightLevelTimeInterval = flight._timeIntervalFlightLevel.getValue(intervalStart);
			let speedTimeInterval = flight._timeIntervalSpeed.getValue(intervalStart);
			flightLevelTimeInterval = !isEmpty(flightLevelTimeInterval) ? flightLevelTimeInterval.toFixed(0) : "-";
			speedTimeInterval = !isEmpty(speedTimeInterval) ? (speedTimeInterval * 1.94384).toFixed(0) : "-";
			labelText.intervals.addInterval(new TimeInterval({
				start: intervalStart,
				stop: intervalStop,
				// TODO make this configurable
				data: flight.identifier + '\r\n ' + padFlightLevel(flightLevelTimeInterval) +
					'    ' + speedTimeInterval + '\r\n  ADES   ' + flight.icaoType
			}));
			intervalStart = intervalStop;
		}
		// noinspection JSCheckFunctionSignatures
		flight.label = new LabelGraphics({
			text: labelText,
			font : (opts ? (opts.flightLabelFontSize ? opts.flightLabelFontSize : 11) : 11) + 'px Courier New',
			fillColor : Color.WHITE,
			outlineColor : Color.WHITE,
			outlineWidth : 1,
			// style : LabelStyle.FILL_AND_OUTLINE,
			style : LabelStyle.FILL,
			showBackground: true,
			horizontalOrigin: HorizontalOrigin.LEFT,
			verticalOrigin : VerticalOrigin.BOTTOM
		});
	};

	/**
	 * Update the Flight's orientation based on the current position definition
	 * @private
	 */
	flight._updateFlightOrientation = function () {
		// noinspection JSCheckFunctionSignatures
		flight.orientation = new VelocityOrientationProperty(flight.position);
	};

	/**
	 * Update the path definition
	 * @private
	 */
	flight._updateFlightPath = function (pLeadTime, pTrailTime, pColor, pWidth) {
		flight.path = new PathGraphics({
			resolution: 1,
			leadTime: pLeadTime,
			trailTime: pTrailTime,
			material: new PolylineGlowMaterialProperty({
				// glowPower: 0.1,
				color: this._ghost ? Color.BLUE : Color[pColor.toUpperCase()]
			}),
			width: pWidth
		})
	};

	/**
	 * Used when a new point is added to ensure we have an availability value
	 * that is the complete duration of the flight. This can be manually
	 * overridden using setFlightStartDatetime()
	 * @param datetime {string|Date}
	 * @private
	 */
	flight._updateFlightStartDatetime = function (datetime) {
		if (typeof datetime === "string") {
			datetime = new Date(datetime);
		}
		try {
			let julianDatetime = JulianDate.fromDate(datetime);
			if (flight._flightStartDatetime === null || julianDatetime < flight._flightStartDatetime) {
				flight._flightStartDatetime = julianDatetime;
			}
		} catch (err) {
			/*console.log(err);*/
		}
	};

	/**
	 * Creates a TimeIntervalCollectionProperty that defines the speed at
	 * regular intervals. This value can be used to provide dynamic labels and
	 * charts.
	 * @private
	 */
	flight._updateFlightTimeIntervalHeading = function() {
		flight._timeIntervalHeading = new TimeIntervalCollectionProperty();
		let intervalStart = JulianDate.clone(flight._flightStartDatetime, new JulianDate());
		let previousPosition = null;
		for (let x = 0; x < flight._getFlightDuration(); x += 3) {
			let intervalStop = JulianDate.addSeconds(flight._flightStartDatetime, x, new JulianDate());
			let position = flight.position.getValue(intervalStart);
			if (position) {
				position = Cartographic.fromCartesian(position);
				if (previousPosition) {
					let heading;
					try {
						if (!isPositionEqual(previousPosition, position)) {
							heading = Cartesian3.angleBetween(previousPosition, position) * 180 / CesiumMath.PI;
						}
					} catch (e) {
						heading = 0.0;
					}
					flight._timeIntervalHeading.intervals.addInterval(new TimeInterval({
						start: intervalStart,
						stop: intervalStop,
						data: heading
					}));
				}
			}
			intervalStart = intervalStop;
			previousPosition = position;
		}
	};

	/**
	 * Creates a TimeIntervalCollectionProperty that defines the flight
	 * level at regular intervals. This value can be used to provide dynamic
	 * labels and charts
	 * @private
	 */
	flight._updateFlightTimeIntervalFlightLevel = function() {
		flight._timeIntervalFlightLevel = new TimeIntervalCollectionProperty();
		let intervalStart = JulianDate.clone(flight._flightStartDatetime, new JulianDate());
		for (let x = 0; x < flight._getFlightDuration(); x += 3) {
			let intervalStop = JulianDate.addSeconds(flight._flightStartDatetime, x, new JulianDate());
			let position = flight.position.getValue(intervalStart);
			if (position) {
				position = Cartographic.fromCartesian(position);
				flight._timeIntervalFlightLevel.intervals.addInterval(new TimeInterval({
					start: intervalStart,
					stop: intervalStop,
					data: position.height/100
				}));
			}
			intervalStart = intervalStop;
		}
	};

	/**
	 * Creates a TimeIntervalCollection that defines the flight's position at
	 * regular intervals.
	 * @private
	 */
	flight._updateFlightTimeIntervalPosition = function() {
		flight._timeIntervalPosition = [];
		let intervalStart = JulianDate.clone(flight._flightStartDatetime, new JulianDate());
		for (let x = 0; x < flight._getFlightDuration(); x += 5) {
			let intervalStop = JulianDate.addSeconds(flight._flightStartDatetime, x, new JulianDate());
			let position = flight.position.getValue(intervalStart);
			if (position) {
				position = Cartographic.fromCartesian(position);
				flight._timeIntervalPosition.push(position.longitude * 180 / CesiumMath.PI, position.latitude* 180 / CesiumMath.PI, position.height);
			}
			intervalStart = intervalStop;
		}
	};

	/**
	 * Creates a TimeIntervalCollectionProperty that defines the speed at
	 * regular intervals. This value can be used to provide dynamic labels and charts
	 * @private
	 */
	flight._updateFlightTimeIntervalSpeed = function() {
		flight._timeIntervalSpeed = new TimeIntervalCollectionProperty();
		let intervalStart = JulianDate.clone(flight._flightStartDatetime, new JulianDate());
		let previousPosition = null;
		for (let x = 0; x < flight._getFlightDuration(); x += 3) {
			let intervalStop = JulianDate.addSeconds(flight._flightStartDatetime, x, new JulianDate());
			let position = flight.position.getValue(intervalStart);
			if (position) {
				if (previousPosition) {
					let distanceTraveledInMeters = Cartesian3.distance(previousPosition, position);
					let speed = (distanceTraveledInMeters > 0 ? distanceTraveledInMeters / 3 : null);
					if (speed > 0) {
						flight._timeIntervalSpeed.intervals.addInterval(new TimeInterval({
							start: intervalStart,
							stop: intervalStop,
							data: speed
						}));
					}
				}
			}
			intervalStart = intervalStop;
			previousPosition = position;
		}
	};

	/**
	 * The wall that drops down from the flight
	 * @param options {Object}
	 * @param options.color {string} the wall color
	 * @param options.outline {boolean} show the wall outline
	 * @param options.opacity {Number} the wall opacity
	 * @param options.outlineWidth {Number} the wall outline width
	 * @param options.isVisible {boolean} wall is visible
	 * @private
	 */
	flight._updateFlightWall = function(options) {
		if (isEmpty(options)) options = {};
		let color = options.color ? options.color : this._ghost ? "BLUE" : "YELLOW";
		let outline = options.outline ? options.outline : false;
		let opacity = options.opacity ? options.opacity : 0.1;
		let outlineWidth = options.outlineWidth ? options.outlineWidth : 1;
		// if (options.isVisible) {
		flight.wall = {
			positions : Cartesian3.fromDegreesArrayHeights(flight._timeIntervalPosition),
			material : Color[color.toUpperCase()].withAlpha(parseFloat(opacity)),
			outline : outline,
			outlineWidth : outlineWidth,
			outlineColor: Color[color.toUpperCase()].withAlpha(parseFloat(opacity))
		}
		// } else {
		// 	flight.wall = {};
		// }
	};

	return flight;

}

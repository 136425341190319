/**
 * Function specifically resolves the simple ICAO name of Airbus aircraft
 * @param icaoModelType
 * @return {string}
 */
function airbusResolver(icaoModelType) {
	if (icaoModelType.slice(0,4) === "A319") {
		return "A319";
	}
	if (icaoModelType.slice(0,4) === "A321") {
		return "A321";
	}
	return icaoModelType.slice(0,3) + "0";
}

/**
 * A utility function that aims to resolve the appropriate model file for a given ICAO
 * aircraft type. If resolution fails, then a default is provided. We should keep a
 * note of failures put into the console so that we can improve the model database.
 * @param icaoModelType {String} the ICAO model code
 * @return {String} the path to the appropriate model
 */
export function modelResolver(icaoModelType) {
    const modelMap = {
        "A300": "./Assets/Models/airbus/A300.glb",
        "A310": "./Assets/Models/airbus/A310.glb",
        "A319": "./Assets/Models/airbus/A319.glb",		// TODO we're missing an A320
        "A321": "./Assets/Models/airbus/A321.glb",
        "A330": "./Assets/Models/airbus/A330.glb",
        "A340": "./Assets/Models/airbus/A340.glb",
        "A380": "./Assets/Models/airbus/A380.glb",
        "B737": "./Assets/Models/boeing/B737.glb",
        "B747": "./Assets/Models/boeing/B747.glb",
        "B757": "./Assets/Models/boeing/B757.glb",
        "B767": "./Assets/Models/boeing/B767.glb",
        "B777": "./Assets/Models/boeing/B777.glb",
        "B787": "./Assets/Models/boeing/B787.glb",
        "CRJ100": "./Assets/Models/bombardier/CRJ100.glb",
        "CRJ700": "./Assets/Models/bombardier/CRJ700.glb",
        "CRJ1000": "./Assets/Models/bombardier/CRJ1000.glb",
        "DHC8": "./Assets/Models/bombardier/DHC8.glb",
        "ERJ145": "./Assets/Models/embraer/ERJ145.glb",
        "ERJ175": "./Assets/Models/embraer/ERJ170.glb",
        "ERJ195": "./Assets/Models/embraer/ERJ190.glb",
        "default": "./Assets/Models/airbus/A319.glb"
    };

    if (icaoModelType === undefined || icaoModelType === null) {
			return modelMap["default"];
		}

    icaoModelType = icaoModelType
        .replace(" ", "")
        .replace("-", "")
        .toUpperCase();
    // Airbus type replace (A338 -> A330)
    if (icaoModelType.slice(0,2) === "A3") {
        icaoModelType = airbusResolver(icaoModelType);
    }
    // Boeing type replace (B738 -> B737)
    if (icaoModelType.slice(0,2) === "B7") {
        icaoModelType = icaoModelType.slice(0,3) + "7";
    }
    let modelPath = modelMap[icaoModelType];
    if (modelPath === undefined) {
        console.warn("ModelResolver.getModel(): Unable to resolve correct model for type " + icaoModelType);
    }
    return (modelPath !== undefined ? modelPath : modelMap["default"]);

}

import {
	Cartesian3, Color,
	Entity,
	SampledPositionProperty,
	SampledProperty
} from 'cesium';
import {Math as CesiumMath} from 'cesium';

/**
 *
 * @param startPoint {Object} the starting point
 * @param startPoint.lat {Number} the starting latitude
 * @param startPoint.lon {Number} the starting longitude
 * @param bearing {Number} bearing in degrees
 * @param distance {Number} distance to travel in meters
 */
export function getNewPosition(startPoint, bearing, distance) {
	let R = 6378.1;
	let bearingInRadians = CesiumMath.toRadians(bearing);
	let d = distance / 1000;
	let lat1 = CesiumMath.toRadians(startPoint.lat);
	let lon1 = CesiumMath.toRadians(startPoint.lon);
	let lat2 = Math.asin( Math.sin(lat1)*Math.cos(d/R) +
		Math.cos(lat1)*Math.sin(d/R)*Math.cos(bearingInRadians));
	let lon2 = lon1 + Math.atan2(Math.sin(bearingInRadians)*Math.sin(d/R)*Math.cos(lat1),
		Math.cos(d/R)-Math.sin(lat1)*Math.sin(lat2));
	return {lat: CesiumMath.toDegrees(lat2), lon: CesiumMath.toDegrees(lon2)};
}

/**
 * Method calculates the left and right limits of a rectangle defined by a
 * center point, rotation, and width.
 * @param centerPoint {object}
 * @param centerPoint.lat {number}
 * @param centerPoint.lon {number}
 * @param centerPoint.alt {number}
 * @param rotation {number}
 * @param diskWidth {number}
 * @return {Cartesian3[]}
 */
export function computeWakeRectangleHierarchy(centerPoint, rotation, diskWidth) {
	console.log(centerPoint);
	let leftLimit = getNewPosition(
		centerPoint,
		rotation-90,
		diskWidth/2
	);
	let rightLimit = getNewPosition(
		{lat: centerPoint.lat, lon: centerPoint.lon},
		rotation+90,
		diskWidth/2);
	return new Cartesian3.fromDegreesArrayHeights([
		leftLimit.lat, leftLimit.lon, centerPoint.alt+(diskWidth/2),
		rightLimit.lat, rightLimit.lon, centerPoint.alt+(diskWidth/2),
		rightLimit.lat, rightLimit.lon, centerPoint.alt-(diskWidth/2),
		leftLimit.lat, leftLimit.lon, centerPoint.alt-(diskWidth/2)
	]);
}


export function createWakeEntity() {
	return new Entity({
		position: new SampledPositionProperty(),
		orientation: null,
		cylinder: {
			topRadius: new SampledProperty(Number),
			bottomRadius: new SampledProperty(Number),
			slices: 24,
			numberOfVerticalLines: 0,
			length: 0.1,
			material: Color.YELLOW.withAlpha(0.5)
		}
	})
}

import React from 'react'
import './Name.css'


class Name extends React.Component {

	constructor (props, context) {
		super(props, context)
		if (!props) props = {};
		this.state = {
			isVisible: props.isVisible,
			text: props.text
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			...this.state, ...nextProps
		})
	}

	render() {
		return(
			<div id={"nimbus-name"} style={{visibility: this.state.isVisible}}>
				<p>{this.state.text}</p>
			</div>
		);
	}

}

export default Name;

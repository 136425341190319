import React from 'react'
import './Loading.css'

let DEFAULT_OPTIONS = {
	border: "2px solid #1EA7FD",
	backgroundColor: "white",
	width: "200px",
	height: "50px",
}

class Loading extends React.Component {

	/**
	 * Component constructor.
	 * @param props {Object} component instantiation properties
	 * @param context {Object} the component context
	 */
	constructor (props, context) {
		super(props, context)
		this.state = {
			isVisible: props.isLoading
		}
	}

	/**
	 * Callback that handles an update of properties.
	 * @param nextProps {Object} the next properties
	 * @param nextContext {Object} the next context
	 */
	componentWillReceiveProps (nextProps, nextContext) {
		this.setState({
			isVisible: nextProps.isVisible
		})
	}

	/**
	 * The render callback.
	 * @return {*}
	 */
	render() {
		return(
			<div className="nimbus-loading" style={{visibility: this.state.isVisible ? "visible" : "hidden"}} >
				<div className="nimbus-loading-content" style={DEFAULT_OPTIONS} >
					<h2>loading data ...</h2>
				</div>
			</div>
		);
	}

}

export default Loading;

/**
 * Split a string based on a delimiter. If no delimiter is defined, the funcion
 * will look to see if it can work out the separator from a list of candidates
 * {not ideal ... should really define one if you can work it out ahead of time}
 * @param string {string} the string to split
 * @param delimiter {string=} the delimiter
 * @return {string[]} the result array
 */
import {isNumber} from "./Helpers";

/**
 * This function will split a string based on a delimited. It will also try to
 * automatically convert number values to numbers
 * @param string {string} the string to split
 * @param delimiter {string=} the delimited (optional)
 * @returns {String[]} the split string
 */
export function splitDelimitedString(string, delimiter) {
	if (!delimiter) {
		delimiter = findDelimiterForString(string);
	}
	let splitString = string.split(delimiter);
	return splitString.map(v=>{return isNumber(v) ? Number(v) : v})
}

/**
 * Try and determine the delimiter for a string.
 * @param string {string} the string to look for a delimiter
 * @return {null|string} the delimiter if one can be found
 */
export function findDelimiterForString(string) {
	let delimiterArray = [",", ";", "\t", ":", "|"];
	for (let i = 0; i <= delimiterArray.length; i++ ) {
		let candidate = delimiterArray[i];
		let data = string.split(candidate);
		if (data.length > 1) {
			return delimiterArray[i];
		}
	}
	return null;
}

/**
 * Ensures that a flight level is always appropriately zero padded. For example
 * 600ft would be 006
 * @param flightLevel {number} the flight level
 * @return {string}
 */
export function padFlightLevel(flightLevel) {
	if (flightLevel < 10) {
		return "00" + flightLevel;
	} else if (flightLevel < 100) {
		return "0" + flightLevel;
	} else {
		return flightLevel.toString();
	}
}

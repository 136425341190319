import React from 'react'
import './Toolbar.css'
import OpenFileDialog from '../../dialogs/openFileDialog'
import openIcon from '../../../../Assets/img/open.png'
import settingsIcon from '../../../../Assets/img/settings.png'
import {isaExternalToFlightDict} from '../../../../utilities/IsaExternalToFlightDict'
import {wvssjToFlightDict, wvssxToFlightDict} from '../../../../utilities/WvssToFlightDict'
import SettingsDialog from "../../dialogs/settingsDialog";
import Modal from "../../modal";

class Toolbar extends React.Component {

	/**
	 * The constructor.
	 * @param props {Object} instantiation properties
	 * @param context {Object=} instantiation context
	 */
	constructor (props, context) {
		super(props, context)
		this.state = {
			isVisible: true,
			isOpenFileVisible: false,
			isSettingsVisible: false,
			scenario: props.scenario ? props.scenario : {}
		}
		if (props.scenario) this.onUpdateSetting = props.scenario.onUpdateSetting;
	}

	/**
	 * Callback fired when component props are updated.
	 * @param nextProps
	 * @param nextContext
	 */
	componentWillReceiveProps (nextProps, nextContext) {
		this.setState({
			scenario: nextProps.scenario
		})
	}

	/**
	 * Callback fired when the user clicks on the open file icon.
	 * @param event {Event} the event
	 */
	onClickOpenIcon(event) {
		this.setState({
			isOpenFileVisible: true,
		})
	}

	/**
	 * Callback fired when the user clicks on the open file icon.
	 * @param event {Event} the event
	 */
	onClickSettingsIcon(event) {
		this.setState({
			isSettingsVisible: true,
		})
	}

	/**
	 * Callback that is passed down to handle opening of data files.
	 * @param contents {String} the contents of the file
	 * @param fileType {String} the file type
	 */
	onOpenFile(contents, fileType="EXTERNAL") {
		this.setState({
			isOpenFileVisible: false
		}, () => {
			this.state.scenario.onUpdateScenario({
				isLoading: true
			}, () => {
				// A small timeout here gives the UI time to rerender and show the
				// loading icon before the thread is blocked by the data conversion
				setTimeout(() => {
					let jsonObject;
					let updateObject = {isLoading: false};
					switch (fileType) {
						case "wvssj":
							// The JSON string often has trailing commas which fuck up JSON.parse() so
							// we need to clean those first.
							let regex = /\,(?!\s*?[\{\[\"\'\w])/g;
							contents = contents.replace(regex, '');
							jsonObject = JSON.parse(contents);
							updateObject["flightData"] = wvssjToFlightDict(jsonObject);
							updateObject["name"] = "FROM WVSS";
							updateObject["description"] = "Original data type: " + jsonObject["OriginalDataType"];
							updateObject["settings"] = {...this.state.scenario.settings, ...{"isWvssReplay": {value: true}}};
							break
						case "wvssx":
							jsonObject = JSON.parse(contents);
							updateObject["flightData"] = wvssxToFlightDict(jsonObject);
							updateObject["name"] = "FROM WVSS";
							updateObject["description"] = "Data produced by the WVSS software";
							break
						default:
							updateObject["flightData"] = isaExternalToFlightDict(contents);
					}
					this.state.scenario.onUpdateScenario(updateObject);
				}, 250);
			});
		})
	}

	/**
	 * Main render callback.
	 * @return {*}
	 */
	render() {
		let openFileDialog = <OpenFileDialog onLoad={this.onOpenFile.bind(this)} />;
		let settingsDialog = <SettingsDialog settings={this.state.scenario.settings}
											 onUpdateSetting={this.onUpdateSetting} />;
		return(
			<div id="nimbus-toolbar">
				<a onClick={this.onClickOpenIcon.bind(this)}>
					<img className="nimbus-toolbar-icon"
							 alt={"open file icon"}
							 src={openIcon}
							 title={"Open a Nimbus scenario file"} />
				</a>
				{/*<a onClick={this.onClickSettingsIcon.bind(this)}>*/}
				{/*	<img className="nimbus-toolbar-icon"*/}
				{/*		 alt={"open file icon"}*/}
				{/*		 src={settingsIcon}*/}
				{/*		 title={"Open a Nimbus scenario file"} />*/}
				{/*</a>*/}
				<Modal isVisible={this.state.isOpenFileVisible}
							 title={"Open Data File"}
							 body={openFileDialog}
							 onClose={() => {
								 console.log("onClose function")
								 this.setState({isOpenFileVisible: false})
							 }}
							 styling={{width: "500px", height: "140px"}} />
				<Modal isVisible={this.state.isSettingsVisible}
					   title={"Settings"}
					   body={settingsDialog}
					   onClose={() => {
						   this.setState({isSettingsVisible: false})
					   }}
					   styling={{width: "500px", height: "600px", overflowY: "scroll"}} />
			</div>
		);
	}

}

export default Toolbar;


export const SETTING_DATA_TYPE = {
	STRING: "STRING",
	NUMBER: "NUMBER",
	BOOL: "BOOL",
	COLOR: "COLOR"
}

/**
 * @typedef NimbusSettings{Object}
 * @property flightLabelFontColor {string}
 * @property flightLabelFontSize {number}
 * @property flightLabelOpacity {number}
 * @property flightMinimumPixelSize {number}
 * @property flightModelSelectedSilhouetteAlpha {number)
 * @property flightModelSelectedSilhouetteColor {string}
 * @property flightModelSelectedSilhouetteSize {number}
 * @property flightPathColor {string}
 * @property flightPathLeadTime {number}
 * @property flightPathTrailTime {number}
 * @property flightPathWidth {number}
 * @property show3dAircraft {boolean}
 * @property isWvssReplay {boolean}
 * @property show3dBuildings {boolean}
 */
export const DEFAULT_SETTINGS = {
	flightMinimumPixelSize: {type: SETTING_DATA_TYPE.NUMBER, value: 24, min: 0, max: 50},
	flightModelSelectedSilhouetteAlpha: {type: SETTING_DATA_TYPE.NUMBER, value: 0.15, min: 0.0, max: 1.0},
	flightModelSelectedSilhouetteColor: {type: SETTING_DATA_TYPE.COLOR, value: "RED"},
	flightModelSelectedSilhouetteSize: {type: SETTING_DATA_TYPE.NUMBER, value: 3, min: 1, max: 10},
	flightPathLeadTime: {type: SETTING_DATA_TYPE.NUMBER, value: 60, min: 0, max: 1800},
	flightPathTrailTime: {type: SETTING_DATA_TYPE.NUMBER, value: 300, min: 0, max: 1800},
	flightPathColor: {type: SETTING_DATA_TYPE.COLOR, value: "YELLOW"},
	flightPathWidth: {type: SETTING_DATA_TYPE.NUMBER, value: 2, min: 1, max: 10},
	flightLabelFontSize: {type: SETTING_DATA_TYPE.NUMBER, value: 11, min: 7, max: 20},
	flightLabelFontColor: {type: SETTING_DATA_TYPE.COLOR, value: "WHITE"},
	flightLabelOpacity: {type: SETTING_DATA_TYPE.NUMBER, value: 0.7, min: 0, max: 1.0},
	isWvssReplay: {type: SETTING_DATA_TYPE.BOOL, value: false},
	show3dAircraft: {type: SETTING_DATA_TYPE.BOOL, value: true},
	show3dBuildings: {type: SETTING_DATA_TYPE.BOOL, value: true},
}

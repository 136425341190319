import React from 'react';
import './Graph.css'
import {AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, LineChart, Line
} from 'recharts';

class Graph extends React.Component {

	constructor (props, context) {
		super(props, context)
		if (!props) props = {};
		this.state = {
			graph_data: props.graph_data,
			flight_id: props.flight_id,
			current_time: props.current_time
		}
	}

/*	componentDidMount() {
		this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}*/


	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({graph_data: nextProps.graph_data ? nextProps.graph_data : this.state.graph_data});
		this.setState({flight_id: nextProps.flight_id ? nextProps.flight_id : this.state.flight_id});
		this.setState({current_time: nextProps.current_time ? nextProps.current_time : this.state.current_time});
	}


	render() {
		let currentTimePercentage = parseInt(((this.state.current_time / this.state.graph_data[this.state.graph_data.length-1].time) * 100).toString().split('.')[0]);
		let currentTimePercentage_minus;
		let currentTimePercentage_plus;
		if (currentTimePercentage <= 5){
			currentTimePercentage_minus = 0;
		} else {
			currentTimePercentage_minus = currentTimePercentage - 5;
		}
		if (currentTimePercentage >= 100){
			currentTimePercentage_plus = 5;
		} else {
			currentTimePercentage_plus = currentTimePercentage + 5;
		}
		return (
			<div id={"nimbus-graph"}>
				<center><b><h4 style={{ color: 'white'}}>{this.state.flight_id}</h4></b></center>

				<LineChart width={300}
									 height={185}
									 data={this.state.graph_data}
									 margin={{
										 top: 0, right: 40, left: -15, bottom: 0,
									 }}
									 fill={"#8884d8"}>
					<defs>
						<linearGradient id="colorUv" x1="0%" y1="0%" x2="100%" y2="0%">
							<stop offset="0%" stopColor="blue" />
							<stop offset={currentTimePercentage_minus+"%"} stopColor="blue" />
							<stop offset={currentTimePercentage+"%"} stopColor="white" />
							<stop offset={currentTimePercentage_plus+"%"} stopColor="blue" />
							<stop offset="100%" stopColor="blue" />
						</linearGradient>
					</defs>
					<Line
						type="monotone"
						dataKey="altitude"
						stroke="url(#colorUv)"
						strokeWidth={5}
						dot={false}
						activeDot={false}
						data={this.state.graph_data}
					/>
					<CartesianGrid strokeDasharray="3 3" />
					{/*<XAxis dataKey="blank" />*/}
					<XAxis dataKey="name" />
					<YAxis />
				</LineChart>
			</div>
		);
	}
}

export default Graph;

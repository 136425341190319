import React from 'react'
import './Description.css'


class Description extends React.Component {

	constructor (props, context) {
		super(props, context)
		if (!props) props = {};
		this.state = {
			isVisible: props.isVisible,
			text: props.text,
			url: props.url
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			...this.state, ...nextProps.state
		})
	}

	render() {
		return(
			<div id={"nimbus-description"} style={{visibility: this.state.isVisible}}>
				<p>
					{this.state.url ? <span><a href={this.state.url} target="_blank">&#128172;</a> </span> : null}
					{this.state.text}
				</p>
			</div>
		);
	}

}

export default Description;

import React, {Component} from "react";

import "./SettingsDialog.css";
import {SETTING_DATA_TYPE} from "../../../../settings";


class SettingsDialog extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			rows: this.getSettingRows(props.settings),
			settings: props.settings
		};
		this.onUpdateSetting = props.onUpdateSetting;
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			rows: this.getSettingRows(nextProps.settings),
			settings: nextProps.settings
		});
	}

	onChangeSetting(key, value) {
		this.onUpdateSetting(key, value);
	}

	renderInput(name, definition) {
		let value = definition.value;
		let type = definition.type;
		let input;
		switch (type) {
			case SETTING_DATA_TYPE.STRING:
				input = this.renderStringInput(name, value);
				break;
			case SETTING_DATA_TYPE.NUMBER:
				let min = definition.min;
				let max = definition.max;
				input = this.renderNumberInput(name, value, min, max);
				break;
			case SETTING_DATA_TYPE.BOOL:
				input = this.renderBooleanInput(name, value);
				break;
			case SETTING_DATA_TYPE.COLOR:
				input = this.renderColorInput(name, value);
				break;
			default:
				console.warn("Unrecognized setting type: '" + type + "'");
		}
		return input;
	}

	renderBooleanInput(key, value) {
		value = Boolean(value);
		// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_switch
		return <label className="switch">
			<input type="checkbox" value={value}
				   onChange={(event)=>{
				   	this.onChangeSetting(key, event.target.value)}
				   } />
			<span className="slider round"/>
		</label>
	}

	renderColorInput(key, value) {
		return <label>
			<input type="color" value={value}
				   onChange={(event)=>{
					   this.onChangeSetting(key, event.target.value)}
				   }/>
		</label>
	}

	renderNumberInput(key, value, min, max) {
		return <span>
			Min: {min}
			<input value={value} step={max <= 1 ? 0.1 : 1} type="range" min={min} max={max}
				   onChange={(event)=>{
				   	this.onChangeSetting(key, Number(event.target.value))}
				   } />
			Max: {max}
		</span>
	}

	renderStringInput(key, value) {
		return <input value={value} type="text"
					  onChange={(event)=>{
					  	this.onChangeSetting(key, event.target.value)}
					  } />
	}

	getSettingRows(settings) {
		let settingsRows = [];
		if (settings !== undefined) {
			Object.keys(settings).forEach(settingName=>{
				let settingDefinition = settings[settingName];
				let input = this.renderInput(settingName, settingDefinition);
				if (input !== null) {
					settingsRows.push(<tr key={settingName}>
						<td>{settingName}</td>
						<td>{input}</td>
					</tr>);
				}
			})
		}
		return settingsRows;
	}

	render() {
		return <div>
			<table className={"settings-table"}>
				<tbody>
				{this.state.rows}
				</tbody>
			</table>
		</div>
	}

}

export default  SettingsDialog;

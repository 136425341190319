import React, { createRef } from 'react'
import Dropzone from 'react-dropzone'
import './OpenFileDialog.css'
import { parseSingleFile } from '../../../../utilities/FileUtil.js'

class OpenFileDialog extends React.Component {

	/**
	 * Component constructor.
	 * @param props {Object} instantiation properties
	 */
	constructor(props) {
		super(props);
		this.dropzoneRef = createRef();
		const onLoad = props.onLoad ? props.onLoad : (contents) => {console.log(contents)}
		this.onLoadFile = function(file) {
			parseSingleFile(file, onLoad);
		}
	}

	/**
	 * Fired when the user drops a file or clicks browse to find. This function
	 * wrapped the provided onFileLoad callback in a promise so that the model
	 * can display a loading icon while the file is being parsed.
	 * @param file {File} the file
	 * @return {Promise<void>}
	 */
	onDrop = ([file]) => {
		this.onLoadFile(file);
	}

	/**
	 * This callback opens the browsers file browse dialog. Used in cases where
	 * the user clicks to browse for a file rather than drag-drop.
	 */
	openDialog = () => {
		// Note that the ref is set async, so it might be null at some point
		if (this.dropzoneRef.current) {
			this.dropzoneRef.current.open()
		}
	};

	/**
	 * The main render function.
	 * @return {*}
	 */
	render() {
		return (
			<Dropzone ref={this.dropzoneRef} onDrop={this.onDrop.bind(this)}>
				{({getRootProps, getInputProps}) => (
					<section className="container">
						<div {...getRootProps({className: 'dropzone'})}>
							<input {...getInputProps()} />
							<p>Drag 'n' drop some files here, or click to select files</p>
							<button type="button" onClick={this.openDialog}>
								Browse
							</button>
						</div>
					</section>
				)}
			</Dropzone>
		);
	}

}

export default OpenFileDialog;

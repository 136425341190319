import {Component} from "react";

import { Cartesian3 } from "cesium";
import {isUndefined} from "../utilities/BooleanLogic";


export default class MapCameraManager extends Component {

    componentDidMount() {
        let {camera, flyToLocation} = this.props;
        if(camera) {
            this.handleUpdatedCameraProps({}, flyToLocation, camera);
        }
    }


    componentDidUpdate(prevProps) {
        let {camera, flyToLocation} = this.props;
        if(prevProps.flyToLocation !== flyToLocation) {
            this.handleUpdatedCameraProps(prevProps.flyToLocation, flyToLocation, camera);
        }
    }

    handleUpdatedCameraProps(oldFlyTo, flyToLocation, camera) {
        let newLocationObject = null;

        if(flyToLocation && oldFlyTo !== flyToLocation) {
            newLocationObject = flyToLocation;
        }

        if(newLocationObject) {
            let {lat, lon, alt = undefined, heading, pitch, roll} = newLocationObject;
            const {delay = 0} = newLocationObject;

            let orientation = undefined;

            if(lat === 0.0 && lon === 0.0) {
                // Nobody wants a closeup of the ocean off west Africa
                lat = 35.0;
                lon = -117.0;
                alt = 2500000;
            }

            if(!isUndefined(heading)) {
                orientation = {
                    heading : heading * Math.PI / 180.0,
                    pitch : pitch * Math.PI / 180.0,
                    roll : roll * Math.PI / 180.0,
                };
            }

            camera.flyTo({
                destination : Cartesian3.fromDegrees(lon, lat, alt),
                duration : delay,
                orientation,
            });
        }
    }


    render() {
        return null;
    }
}

/**
 * Returns true if a list contains a target
 * @param list {Array} the list
 * @param target {?} the target object
 * @return {boolean} true if the list contains the object
 */
import { isEmpty } from './BooleanLogic'

export function listContains(list, target) {
	if (!isEmpty(list) && Array.isArray(list)) {
		return list.indexOf(target) > -1;
	} else {
		return false;
	}
}

/**
 * Checks if a string value is actually a number
 * @param n {string|number} the value to check
 * @returns {boolean} true if the value is a number
 */
export function isNumber(n) {
	return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

/**
 * No operation function. Useful default for methods that expect a callback but
 * yu require no action.
 */
export const noop = () => {};

import { Component } from 'react'

import { ScreenSpaceEventHandler } from 'cesium'
// import SSET from 'cesium/Source/Core/ScreenSpaceEventType'

import { noop } from '../utilities/Helpers'

export default class CesiumClickHandler extends Component {

	static defaultProps = {
		onLeftClick : noop
	};

	componentDidMount() {
		const {scene} = this.props.viewer;

		if(scene && scene.canvas) {
			this.screenEvents = new ScreenSpaceEventHandler(scene.canvas);
			this.createInputHandlers();
		}
	}

	componentWillUnmount() {
		if(this.screenEvents && !this.screenEvents.isDestroyed()) {
			this.screenEvents.destroy();
		}
	}

	createInputHandlers() {
		/** Represents a mouse left click event.*/
		// LEFT_CLICK : 2,
		this.screenEvents.setInputAction(this.onMouseLeftClick, 2);
	}

	onMouseLeftClick = (e) => {
		this.props.onLeftClick(e);
	};

	render() {
		return null;
	}
}
